import { createContext, useState } from "react";

const LocationsContext = createContext([{}, () => {}]);

const LocationsProvider = ({ children }) => {
  const [state, setState] = useState({});
  return (
    <LocationsContext.Provider value={[state, setState]}>
      {children}
    </LocationsContext.Provider>
  );
};

export { LocationsContext, LocationsProvider };
