import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faQuestion } from "@fortawesome/free-solid-svg-icons";

import { assetURL } from "../../api/client";
import { getColClass } from "../../utilities";

function CheckboxElement({ item, option, index, children }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  let classValue = "col-auto mb-4 check-cols";
  if (index.length < 2) {
    classValue = getColClass(classValue, item.viewPorts);
  }

  let activeImage = assetURL + "options/" + option.icon + "-active.png";
  let inactiveImage = assetURL + "options/" + option.icon + "-inactive.png";

  return (
    <div className={classValue}>
      <label>
        {children}
        <div className="support-area">
          {!imageLoaded && (
            <div className="support-area__icon">
              <i className="icon-checked">
                <FontAwesomeIcon icon={faCheck} />
              </i>
              <FontAwesomeIcon icon={faQuestion} />
            </div>
          )}

          <div className="support-area__image" hidden={!imageLoaded}>
            <i className="icon-checked">
              <FontAwesomeIcon icon={faCheck} />
            </i>
            {option.icon && (
              <img
                className="inactive-image"
                src={inactiveImage}
                alt={option.shortTitle}
                onLoad={() => setImageLoaded(true)}
              />
            )}
            {option.icon && (
              <img
                className="active-image"
                src={activeImage}
                alt={option.shortTitle}
                onLoad={() => setImageLoaded(true)}
              />
            )}
          </div>

          <div className="support-area__title text-center">
            {option[item.optionLabelField]}
          </div>
        </div>
      </label>
    </div>
  );
}

export default CheckboxElement;
