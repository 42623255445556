import React, { useEffect, useState } from "react";

import { getColClass } from "../../utilities";

function InputElement({ item, field, meta, index }) {
  const [classValue, setClassValue] = useState("col-12");

  useEffect(() => {
    if (index.length < 2) {
      let newClassValue = getColClass(classValue, item.viewPorts);
      setClassValue(newClassValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row justify-content-center">
      <div className={classValue}>
        <div className="input-element">
          {!item.hideLabel && <div className="input-title">{item.label}</div>}
          <input
            type={item.inputType}
            id={item.identifier}
            placeholder={item.placeholder}
            required={item.required}
            readOnly={item.readonly}
            autoComplete={item.autocomplete}
            {...field}
          />
          {meta.error && <div className="alert alert-danger">{meta.error}</div>}
        </div>
      </div>
    </div>
  );
}

export default InputElement;
