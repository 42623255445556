import { createContext, useState } from "react";

const ServicesContext = createContext([{}, () => {}]);

const ServicesProvider = ({ children }) => {
  const [state, setState] = useState("");
  return (
    <ServicesContext.Provider value={[state, setState]}>
      {children}
    </ServicesContext.Provider>
  );
};

export { ServicesContext, ServicesProvider };
