import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

import { getColClass } from "../../utilities";
import { assetURL } from "../../api/client";

function InputElement({ item, index }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [classValue, setClassValue] = useState("col-12");

  let altAttr = "Element";
  if (item.alt) altAttr = item.alt;

  useEffect(() => {
    if (index.length < 2) {
      let newClassValue = getColClass(classValue, item.viewPorts);
      setClassValue(newClassValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row justify-content-center">
      <div className={classValue}>
        <div className="image-element">
          {!imageLoaded && <FontAwesomeIcon icon={faQuestion} />}
          <img
            src={assetURL + "" + item.src}
            onLoad={() => setImageLoaded(true)}
            className="img-fluid"
            alt={altAttr}
          />
        </div>
      </div>
    </div>
  );
}

export default InputElement;
