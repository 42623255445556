import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { getColClass } from "../../utilities";

function RadioElement({ item, option, index, children, values }) {
  let classValue = "col-12";
  if (index.length < 2) {
    classValue = getColClass(classValue, item.viewPorts);
  }

  return (
    <div className={classValue}>
      <label>
        {children}
        <div
          className={
            values[item.identifier] === option.identifier.toString()
              ? "in-element checked"
              : "in-element"
          }
        >
          <i className="icon-checked">
            <FontAwesomeIcon icon={faCheck} />
          </i>
          {option[item.optionLabelField]}
        </div>
      </label>
    </div>
  );
}

export default RadioElement;
