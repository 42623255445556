import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../assets/logo.svg";
import ASDialog from "./ASDialog";

const Header = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-6 col-lg order-3  main-title-container">
            <button onClick={() => setOpen(true)} className="header-btn">
              <div className="main-title">
                Gute-Pflege-Center<span>Onlineberatung</span>
              </div>
            </button>
            <ASDialog
              text={{
                "title": "Onlineberatung beenden",
                "description":
                  "Sind Sie sich sicher, dass Sie die Onlineberatung beenden wollen?",
                "negBtn": "Onlineberatung nicht beenden",
                "posBtn": "Onlineberatung beenden",
              }}
              open={open}
              setOpen={setOpen}
              handleConfirm={() => {
                setOpen(false);
                navigate("/", { replace: true });
              }}
            />
          </div>
          {/* <div className="col-6 col-lg-4 order-2 order-lg-2 text-center">
              <a href="#" className="btn btn--login">
                <FontAwesomeIcon icon={faUser} /> Anmelden
              </a>
              <a href="#" className="btn btn--register">
                Registrieren
              </a>
            </div> */}
          <div className="col-6 col-lg-auto order-1 ">
            <button onClick={() => setOpen(true)} className="header-btn">
              <img className="logo" src={logo} alt="logo" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
