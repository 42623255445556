import { createContext, useState } from "react";

const FormContext = createContext([{}, () => {}]);

const FormProvider = ({ children }) => {
  const [form, setForm] = useState({
    "update": false,
    "values": {},
  });
  return (
    <FormContext.Provider value={[form, setForm]}>
      {children}
    </FormContext.Provider>
  );
};

export { FormContext, FormProvider };
