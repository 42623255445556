import React, { useContext } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Collapse from "react-bootstrap/Collapse";
import { StateContext } from "../contexts/state";

function Notification() {
  const [stateCon, setStateCon] = useContext(StateContext);

  const updateStateCon = (field, value) => {
    let newState = { ...stateCon };
    newState[field] = value;
    setStateCon(newState);
  };

  if (!stateCon) return null;
  if (!stateCon.notifications) return null;

  return (
    <Collapse in={stateCon?.notifications?.length > 0}>
      <div
        className={
          stateCon?.notifications?.lenght > 0
            ? "notification bg-danger full"
            : "notification bg-danger"
        }
      >
        {stateCon?.notifications && (
          <div className="texts">
            {stateCon?.notifications?.map((text, i) => (
              <p key={i}>{text}</p>
            ))}
          </div>
        )}

        {stateCon?.notifications && (
          <button
            type="button"
            className="btn"
            onClick={() => updateStateCon("notifications", [])}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
      </div>
    </Collapse>
  );
}

export default Notification;
