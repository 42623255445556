import React, { useEffect, useState, useContext } from "react";
import * as Sentry from "@sentry/react";
import { DayPicker } from "react-day-picker";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import "react-day-picker/dist/style.css";

import { cleanList, getColClass } from "../../utilities";
import { putStep } from "../../api/client";
import { FormContext } from "../../contexts/form";
import { StepsContext } from "../../contexts/steps";

function DateElement({
  form,
  item,
  meta,
  index,
  values,
  stepID,
  errors,
  setFieldValue,
}) {
  const [stepsCon, setStepsCon] = useContext(StepsContext);
  const [formCon, setFormCon] = useContext(FormContext);

  const [classValue, setClassValue] = useState("col-12");
  const [selected, setSelected] = useState();
  const [show, setShow] = useState(false);

  const [disabled, setDisabled] = useState([]);

  const handleShow = () => {
    if (form.values[item.identifier]) {
      setSelected(new Date(form.values[item.identifier]));
    }
    setShow(true);
  };

  const getString = () => {
    if (values[item.identifier]) {
      let dte = new Date(values[item.identifier]);
      return format(dte, "PPP", { locale: de });
    }

    return "< Bitte auswählen";
    // return format(selected, "PPP", { locale: de });
  };

  const setFormUpdate = (bool) => {
    let newForm = { ...formCon };
    newForm["update"] = bool;
    setFormCon(newForm);
  };

  const updateStep = async (id, valObject) => {
    let result = await putStep(id, valObject)
      .then((response) => {
        if (response.data.steps !== undefined) {
          let index = _.findIndex(stepsCon?.steps, function (o) {
            return o.identifier === id;
          });

          let newState = { ...stepsCon };
          let newSteps = [...stepsCon?.steps];
          newSteps[index].renderables = response.data.steps.renderables;
          newState["steps"] = newSteps;
          newState["stepData"] = newSteps[index];
          setStepsCon(newState);
        }

        return response;
      })
      .catch((err) => {
        Sentry.captureException(err);
        return err;
      });
    return result.data;
  };

  const save = async () => {
    setShow(false);

    let fDate = format(selected, "yyyy-MM-dd");
    form.setFieldValue(item.identifier, fDate);

    let valObject = {};
    valObject[item.identifier] = fDate;
    let result = await updateStep(stepID, valObject);

    // signal start of update
    setFormUpdate(true);

    // check if values got deleted
    if (result.values) {
      if (!_.isEqual(result.values, values)) {
        let list = cleanList(stepsCon?.stepData?.renderables);
        list.forEach((item) => {
          if (!result.values.hasOwnProperty(item.identifier)) {
            result.values[item.identifier] = "";
            if (_.isArray(values[item.identifier])) {
              setFieldValue(item.identifier, []);
            }
            setFieldValue(item.identifier, "");
          }
        });
      }

      let newForm = { ...formCon };
      newForm["values"] = result.values;
      setFormCon(newForm);
    }

    // add possible errors
    if (result.errors && result.errors.length !== 0) {
      form.setFieldError(item.identifier, result.errors[item.identifier]);
    } else {
      let newErrors = _.omit(errors, [item.identifier]);
      form.setErrors(newErrors);
    }

    // signal end of update
    setFormUpdate(false);
  };

  useEffect(() => {
    if (index.length < 2) {
      let newClassValue = getColClass(classValue, item.viewPorts);
      setClassValue(newClassValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (form.values[item.identifier])
      setSelected(new Date(form.values[item.identifier]));
    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    let newRange = [];
    newRange.push({ dayOfWeek: [0, 6] });
    if (item?.config?.holidays?.length > 0) {
      item?.config?.holidays.forEach((item) => {
        newRange.push(new Date(item));
      });
    }
    if (item?.config?.range?.from) {
      newRange.push({ before: new Date(item?.config?.range?.from) });
    }
    if (item?.config?.range?.to) {
      newRange.push({ after: new Date(item?.config?.range?.to) });
    }
    setDisabled(newRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <div className="row justify-content-center">
      <div className={classValue}>
        <div className="date-element">
          {!item.hideLabel && <div className="input-title">{item.label}</div>}

          <div className="row align-items-center">
            <div className="col">
              <div className="bg-acc1">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <button
                      className="btn btn-blue btn-date"
                      type="button"
                      onClick={() => handleShow()}
                    >
                      <FontAwesomeIcon icon={faCalendarDay} size="2x" />
                    </button>
                  </div>
                  <div className="col">
                    <p className="text-acc2">{getString()}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={() => setShow(false)}
            className="date-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Datum auswählen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DayPicker
                mode="single"
                locale={de}
                disabled={disabled}
                selected={selected}
                onSelect={setSelected}
                // showOutsideDays
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-blue"
                onClick={save}
                disabled={!selected}
              >
                Speichern
              </button>
            </Modal.Footer>
          </Modal>
          {meta.error && <div className="alert alert-danger">{meta.error}</div>}
        </div>
      </div>
    </div>
  );
}

export default DateElement;
