import React, { useEffect, useState, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { clearSession } from "../../api/client";
import { StepsContext } from "../../contexts/steps";
import { StateContext } from "../../contexts/state";

function BaseView(props) {
  const [, setStepsCon] = useContext(StepsContext);
  const [, setStateCon] = useContext(StateContext);

  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const clrSession = () => {
    window.sessionStorage.removeItem("sidebarData");
    setStateCon({ "status": false, "notifications": [] });
    setStepsCon({ "current": 0, "steps": [], "stepData": {} });
    clearSession()
      .then((response) => {
        if (response.data.success) {
          setError(false);
          setTimeout(function () {
            setStateCon({ "status": true, "notifications": [] });
            navigate("/start", { replace: true });
          }, 500);
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        setError(true);
      });
  };

  useEffect(() => {
    clrSession();
    // eslint-disable-next-line
  }, []);

  if (!error) return null;

  return (
    <div className="container">
      <p>Hoppla, etwas ist schief gelaufen. Bitte versuchen sie es erneut.</p>
      <button className="btn btn-danger" onClick={() => clrSession()}>
        erneut versuchen
      </button>
    </div>
  );
}

export default BaseView;
