import { createContext, useState } from "react";

const LoadingContext = createContext([{}, () => {}]);

const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState([{ "bool": true, "show": true }]);
  return (
    <LoadingContext.Provider value={[loading, setLoading]}>
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingProvider };
