import React, { useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

import { loadStep } from "../../api/client";
import LinkElement from "../form/LinkElement";
import TextElement from "../form/TextElement";

// import { assetURL } from "../../api/client";
import { LoadingContext } from "../../contexts/loading";
import { StepsContext } from "../../contexts/steps";
import { FormContext } from "../../contexts/form";

function PdfView() {
  const [, setLoadingCon] = useContext(LoadingContext);
  const [stepsCon, setStepsCon] = useContext(StepsContext);
  const [formCon, setFormCon] = useContext(FormContext);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let id = location.pathname.replace("/", "");
    loadStep(id)
      .then((response) => {
        if (response.data.steps !== undefined) {
          let index = _.findIndex(stepsCon?.steps, function (o) {
            return o.identifier === id;
          });
          let newState = { ...stepsCon };
          newState["current"] = index;
          setStepsCon(newState);

          let newSteps = stepsCon?.steps;
          newSteps[index].renderables = response.data.steps.renderables;
          if (response.data.steps.api) {
            newSteps[index].api = response.data.steps.api;
          }
          newState["steps"] = newSteps;
          newState["stepData"] = newSteps[index];
          setStepsCon(newState);

          let newForm = { ...formCon };
          newForm["values"] = response.data.values;
          setFormCon(newForm);
        }
        setLoadingCon({ "bool": false, "show": false });
      })
      .catch((err) => {
        Sentry.captureException(err);
        navigate("/", { replace: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (stepsCon?.stepData?.renderables === undefined) return null;
  if (stepsCon?.stepData?.renderables.length === 0) return null;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-8 my-5">
          <div className="mb-5 text-page">
            {stepsCon?.stepData?.renderables.map((item, rowIndex) => {
              if (!item.renderables) {
                let classValue = "col";
                rowIndex === 0 ? (classValue += " first") : (classValue += "");

                let options = {
                  item: item,
                  index: [rowIndex],
                };

                if (item.type === "text") {
                  return (
                    <div className="row" key={rowIndex}>
                      <div className={classValue}>
                        <TextElement {...options} />
                      </div>
                    </div>
                  );
                }

                if (item.type === "link") {
                  return (
                    <div className="row" key={rowIndex}>
                      <div className={classValue}>
                        <LinkElement {...options} />
                      </div>
                    </div>
                  );
                }
              }

              return null;
            })}
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-auto mb-5">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => navigate("/", { replace: true })}
          >
            Onlineberatung beenden
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PdfView;
