import React, { useEffect, useState, useContext } from "react";
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

import { loadResult } from "../../api/client";
import { loadStep } from "../../api/client";
// import { assetURL } from "../../api/client";

import StepsBar from "../StepsBar";
import Locations from "../Locations";
import FormElement from "../form/FormElement";
import { LoadingContext } from "../../contexts/loading";
import { StepsContext } from "../../contexts/steps";
import { StateContext } from "../../contexts/state";
import { FormContext } from "../../contexts/form";
import { ServicesContext } from "../../contexts/services";
import { ScrollContext } from "../../contexts/scroll";

const image2 = require("../../assets/icons/step-5-teaser-icon-1.svg");

function ResultView() {
  const [, setLoadingCon] = useContext(LoadingContext);
  const [, setServicesCon] = useContext(ServicesContext);
  const [, setScrollCon] = useContext(ScrollContext);
  const [stepsCon, setStepsCon] = useContext(StepsContext);
  const [stateCon, setStateCon] = useContext(StateContext);
  const [formCon, setFormCon] = useContext(FormContext);

  const [serviceText, setServiceText] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const toTop = () => {
    setScrollCon("");
    setTimeout(function () {
      setScrollCon("top");
    }, 500);
  };

  useEffect(() => {
    toTop();

    let newState = { ...stateCon };
    newState["notification"] = "";
    setStateCon(newState);

    let id = location.pathname.replace("/", "");

    // load step
    loadStep(id)
      .then((response) => {
        if (response.data.steps !== undefined) {
          if ("timestamp" in response.data.values) {
            navigate("/", { replace: true });
          }

          let index = _.findIndex(stepsCon?.steps, function (o) {
            return o.identifier === id;
          });
          let newState = { ...stepsCon };
          newState["current"] = index;

          let newSteps = [...stepsCon?.steps];
          if (response.data.steps.renderables.length > 0) {
            newSteps[index].renderables = response.data.steps.renderables;
          }
          if (response.data.steps.api) {
            newSteps[index].api = response?.data?.steps?.api;
          }
          if (response.data.steps.validation) {
            newSteps[index].validation = response.data.steps.validation;
          }
          newState["steps"] = newSteps;
          newState["stepData"] = newSteps[index];

          setStepsCon(newState);

          let newForm = { ...formCon };
          if (response.data.values.length === 0) {
            newForm["values"] = {};
          } else {
            newForm["values"] = response.data.values;
          }
          setFormCon(newForm);

          if (response.data.steps.api !== undefined) {
            loadResult(response.data.steps.api.services)
              .then((response) => {
                let text = "";
                if (response.data.services.length > 0) {
                  response.data.services.forEach((item, index) => {
                    index === 0
                      ? (text += item.title)
                      : (text += " oder " + item.title);
                  });
                } else {
                  text = "Leider wurde keine passende Leistung gefunden.";
                }
                setServiceText(text);
                setServicesCon(text);
                setLoadingCon({ "bool": false, "show": false });
              })
              .catch((err) => {
                Sentry.captureException(err);
                navigate("/", { replace: true });
              });
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        navigate("/", { replace: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my-5">
      <div className="mb-5">
        <div>
          <div className="title">
            <h2>Vielen Dank für Ihre Angaben!</h2>
            <p>Folgende Angebote können wir uns für Sie vorstellen:</p>
          </div>

          <div className="my-5">
            <div className="teaser blue">
              <div className="icon">
                {/* <img src={assetURL + "icons/icon-1.svg"} alt="icon" /> */}
                <img src={image2.default} alt="icon" />
              </div>
              <div className="text">
                <p>{serviceText}</p>
              </div>
            </div>
          </div>

          <div>
            <p>
              Für pflegebedürftige Menschen bietet die Evangelische Heimstiftung
              passgenaue Angebote: Von der Betreuung in einem Pflegeheim über
              Pflegewohnen bis zum gemeinschaftlichen Leben in einer betreuten
              Wohngemeinschaft oder dem Besuch einer Tagespflege. Unsere
              ambulante Pflege, die Mobilen Dienste oder Hauswirtschaftliche
              Hilfe kommen zu Ihnen nach Hause. Lassen Sie sich im
              Gute-Pflege-Center persönlich beraten, nennen Sie uns einfach
              Ihren Wunschtermin.
            </p>
            <button
              className="btn btn-blue mt-3"
              onClick={() => setScrollCon("contactFormHook")}
            >
              Jetzt Termin vereinbaren
            </button>
          </div>
        </div>
      </div>

      {stepsCon?.stepData.api !== undefined && <Locations />}

      <div className="personal-advice-from" id="contactFormHook">
        <h5 className="clr-title">
          Persönliche Beratung im Gute-Pflege-Center
        </h5>
      </div>
      <div className="personal-advice-from__inner">
        <FormElement showLoading={true}>
          <div className="steps-navigation">
            <div className="row align-content-center justify-content-between">
              <div className="col-auto">
                <button
                  className="step-btn step-btn--next active"
                  type="submit"
                >
                  Absenden <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            </div>
          </div>
        </FormElement>
      </div>

      <div className="mt-5 text-after-form d-none">
        <h4>Ihre Angaben speichern</h4>
        <p>
          Die Angaben, die Sie bisher gemacht haben, können Sie auch speichern.
          Sie können so später zum Assistenten zurückkehren ohne Ihre Angaben
          erneut machen zu müssen.
        </p>
        <button className="grey-btn">
          Angaben speichern
          <span className="icon">
            <FontAwesomeIcon icon={faAngleRight} />
          </span>
        </button>
      </div>

      <StepsBar nextVisible={false} />
    </div>
  );
}

export default ResultView;
