import { useEffect, useContext } from "react";
import { LocationsContext } from "../../contexts/locations";

function LocationsObj({ item, form, stepID, values, setFieldValue }) {
  const [locationsCon] = useContext(LocationsContext);

  useEffect(() => {
    setFieldValue(item.identifier, locationsCon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, stepID, values, locationsCon]);

  return null;
}

export default LocationsObj;
