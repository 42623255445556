import React from "react";

import { Modal } from "react-bootstrap";

function ASDialog({ text, open, setOpen, handleConfirm }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{text.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text.description}</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-white" onClick={handleClose}>
          {text.negBtn}
        </button>
        <button className="btn btn-blue" onClick={handleConfirm}>
          {text.posBtn}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ASDialog;
