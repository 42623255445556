import React, { useContext } from "react";
import { ThreeDots } from "react-loader-spinner";
import { LoadingContext } from "../contexts/loading";

function ActivityIndicator() {
  const [loadingCon] = useContext(LoadingContext);

  return (
    <div
      className={
        loadingCon.bool && loadingCon.show
          ? "indicator active"
          : "indicator inactive"
      }
    >
      <div className="indicator-animation">
        <ThreeDots color="#00bbef" height={200} width={200} />
      </div>
    </div>
  );
}

export default ActivityIndicator;
