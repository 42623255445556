import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { cleanList } from "../utilities";
import { StepsContext } from "../contexts/steps";
import { FormContext } from "../contexts/form";
import { LoadingContext } from "../contexts/loading";
import { ScrollContext } from "../contexts/scroll";

function Sidebar() {
  const [stepsCon] = useContext(StepsContext);
  const [formCon] = useContext(FormContext);
  const [, setLoadingCon] = useContext(LoadingContext);
  const [, setScrollCon] = useContext(ScrollContext);

  const [sidebarData, setSidebarData] = useState([]);
  const [ready, setReady] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLink = (path, id) => {
    setScrollCon(id);
    navigate(path);
    setLoadingCon({ "bool": true, "show": false });
  };

  useEffect(() => {
    let loadedSidebarData = window.sessionStorage.getItem("sidebarData");
    if (loadedSidebarData) {
      loadedSidebarData = JSON.parse(loadedSidebarData);
      setSidebarData(loadedSidebarData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stepsCon?.steps[stepsCon?.current]) {
      if (stepsCon?.steps[stepsCon?.current].renderables.length > 0) {
        let renderables = cleanList(
          stepsCon?.steps[stepsCon?.current].renderables
        );
        let newSideBarData = sidebarData;

        renderables.forEach((item, idx) => {
          let index = _.findIndex(newSideBarData, function (o) {
            return o.identifier === item.identifier;
          });
          let dataObj = {
            ...item,
            "order": stepsCon?.current * 10 + idx,
            "stepIndex": stepsCon?.current,
            "path": "/" + stepsCon?.steps[stepsCon?.current].identifier,
          };
          if (index === -1) {
            newSideBarData.push(dataObj);
          } else {
            newSideBarData[index] = dataObj;
          }
        });
        _.sortBy(newSideBarData, ["order"]);

        setSidebarData(newSideBarData);
        window.sessionStorage.setItem(
          "sidebarData",
          JSON.stringify(newSideBarData)
        );
      }
    }
    setReady(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsCon?.steps, formCon?.values, location]);

  if (stepsCon?.current < 2) {
    return (
      <aside className="sidebar">
        <div>
          <h5>Hinweise zur Nutzung</h5>
          <p>
            Die Angaben, die Sie auf den folgenden Seiten machen, werden für die
            laufende Sitzung temporär gespeichert. So haben Sie die Möglichkeit,
            in den Seiten vor- und zurückzublättern, ohne die Daten jeweils neu
            eingeben zu müssen. Erst am Schluss entscheiden Sie, ob Sie die
            Daten an die Evangelische Heimstiftung übertragen und Kontakt mit
            uns aufnehmen möchten.
          </p>
        </div>
      </aside>
    );
  }

  if (stepsCon?.current === stepsCon?.steps?.length - 1) {
    return <aside className="sidebar"></aside>;
  }

  if (ready) {
    return (
      <aside className="sidebar">
        <div className="steps-data">
          <h5 className="blue">Ihre bisherigen Angaben</h5>
          <div className="step-info">
            {sidebarData.map((item, index) => {
              let value = "";

              switch (item.type) {
                case "radio":
                  let labelField = item.optionLabelField;
                  let valueField = item.optionValueField;
                  value = item.options.find(
                    (x) =>
                      x[valueField].toString() ===
                      formCon?.values[item[valueField]]
                  );
                  value ? (value = value[labelField]) : (value = "");
                  break;
                case "checkbox":
                  if (formCon?.values[item.identifier]) {
                    if (_.isArray(formCon?.values[item.identifier])) {
                      formCon?.values[item.identifier].forEach((x) => {
                        let option = item.options.find(
                          (y) => y.identifier === x
                        ).title;
                        value === ""
                          ? (value = option)
                          : (value += ", " + option);
                      });
                    } else {
                      value = formCon?.values[item.identifier];
                    }
                  }
                  break;
                default:
                  formCon?.values[item.identifier]
                    ? (value = formCon?.values[item.identifier])
                    : (value = "");
              }

              if (value === "") return null;
              if (stepsCon?.current <= item.stepIndex) return null;

              return (
                <div
                  className="step-info__item"
                  key={index}
                  onClick={() => handleLink(item.path, item.identifier)}
                >
                  <div className="d-flex flex-column w-100">
                    <div className="title-line">
                      <h6>{item.label}</h6>{" "}
                      <span className="edit-icon">
                        <FontAwesomeIcon icon={faEdit} />
                      </span>
                    </div>
                    <div className="text-line">
                      <FontAwesomeIcon icon={faCheck} />
                      <p>{value}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </aside>
    );
  }

  return <aside className="sidebar"></aside>;
}

export default Sidebar;
