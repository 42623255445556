import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { ThreeDots } from "react-loader-spinner";
import Collapse from "react-bootstrap/Collapse";
import * as Sentry from "@sentry/react";

import { loadResult } from "../api/client";

import { StepsContext } from "../contexts/steps";
import { FormContext } from "../contexts/form";
import { LocationsContext } from "../contexts/locations";

function Locations() {
  const [, setLocationsCon] = useContext(LocationsContext);
  const [stepsCon] = useContext(StepsContext);
  const [formCon] = useContext(FormContext);

  const distanceSteps = [10, 25, 50, 100, 250, 1000, 2500];
  const [locations, setLocations] = useState([]);
  const [errors, setErrors] = useState([]);
  const [distance, setDistance] = useState(0);
  const [distances, setDistances] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterActive, setFilterActive] = useState([]);
  const [renderLocations, setRenderLocations] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const saveLocations = () => {
    setLocationsCon({
      "locations": renderLocations,
      "filter": filterActive,
      "distance": distance,
    });
  };

  const updateRenderLocations = (locationList, dist) => {
    let useDist = distance;
    if (dist) useDist = dist;

    // filter by distance
    let newList = _.filter(locationList, (location) => {
      return location.distance <= useDist;
    });

    // filter by category
    if (filterActive.length > 0) {
      newList = _.filter(newList, (location) => {
        let show = false;
        let catArray = _.map(location.categories, "title");

        catArray.forEach((item) => {
          if (filterActive.indexOf(item) > -1) {
            show = true;
          }
        });

        // && combination
        // let interArray = _.intersection(catArray, filterActive);
        // return interArray.length === filterActive.length;

        return show;
      });
    }

    // sort filtered list
    newList = _.sortBy(newList, ["distance"]);
    setRenderLocations(newList);
  };

  useEffect(() => {
    saveLocations();
    // eslint-disable-next-line
  }, [renderLocations]);

  // on entering the view
  useEffect(() => {
    if (stepsCon?.stepData?.api.location) {
      loadResult(stepsCon?.stepData?.api.location)
        .then((response) => {
          if (response.data.errors) {
            // check for errors
            setErrors(response.data.errors);
          }
          if (response.data.locations.length > 0) {
            // fill locations list
            setLocations(response.data.locations);

            // retrieve distances
            let newList = response.data.locations;
            newList = _.sortBy(newList, ["distance"]);

            // get first element distance
            let firstDist = _.head(newList);
            firstDist = parseFloat(firstDist.distance);

            // get last element + distance
            let lastDist = _.last(newList);
            lastDist = parseFloat(lastDist.distance);

            // fill distance options
            let newDistances = distanceSteps;
            _.remove(newDistances, (o) => {
              return parseFloat(o) < firstDist;
            });
            let ind = _.findIndex(newDistances, (o) => {
              return o > lastDist;
            });
            newDistances = _.take(newDistances, ind + 1);
            setDistances(newDistances);
            setDistance(newDistances[0]);

            // fill category options
            let newCategories = [];
            newList.forEach((entry) => {
              newCategories.push(entry.categories);
            });
            newCategories = _.flatten(newCategories);
            newCategories = _.uniqBy(newCategories, "title");
            setCategories(newCategories);

            // first update
            updateRenderLocations(response.data.locations, newDistances[0]);

            setLoading(false);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [stepsCon?.stepData]);

  useEffect(() => {
    updateRenderLocations(locations);
    // eslint-disable-next-line
  }, [distance, filterActive]);

  if (errors.length > 0) return null;
  if (locations.length === 0) return null;

  return (
    <div className="mb-5">
      <div className="search-panel">
        <div className="row align-items-center">
          <div className="col">
            <p>
              {renderLocations.length} Einrichtungen im Umkreis von
              <span
                className={
                  open ? "filter-val filter-val--show-items" : "filter-val"
                }
                onClick={() => setOpen(!open)}
              >
                {distance} km
                <FontAwesomeIcon icon={faAngleDown} className="icon" />
                <ul>
                  {distances.map((item, index) => (
                    <li onClick={() => setDistance(item)} key={index}>
                      {item} km
                    </li>
                  ))}
                </ul>
              </span>
              um Ihre PLZ <span className="plz">{formCon?.values?.zip}</span>
            </p>
          </div>
        </div>

        {categories.length > 0 && (
          <Collapse in={categories.length > 0}>
            <div className="row mt-2">
              <div className="col">
                <div className="filter-panel">
                  {categories.map((cat, index) => {
                    let active = _.includes(filterActive, cat.title);
                    let classVal = "btn btn-filter me-2";
                    classVal += active ? " active" : "";

                    return (
                      <button
                        key={index}
                        className={classVal}
                        onClick={() => {
                          let newFilterActive = filterActive;
                          newFilterActive = _.xor(newFilterActive, [cat.title]);
                          setFilterActive(newFilterActive);
                        }}
                      >
                        {/* {active && (
                          <span className="me-1">
                            <FontAwesomeIcon icon={faCheck} className="icon" />
                          </span>
                        )} */}
                        {cat.title}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </Collapse>
        )}
      </div>

      <div className="clinics-list mt-3">
        <Collapse in={loading}>
          <div>
            <ThreeDots color="#00bbef" height={200} width={200} />
          </div>
        </Collapse>
        <Collapse in={!loading}>
          <div>
            {renderLocations.length === 0 && (
              <p>Bitte wählen Sie einen größeren Umkreis aus</p>
            )}
            {renderLocations.length > 0 &&
              renderLocations.map((location, index) => (
                <a
                  href={location.link}
                  className="clinic-teaser"
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <div className="row w-100">
                    <div className="col-12 col-lg-auto pe-0">
                      <div className="clinic-teaser__img">
                        <img
                          src={location.image}
                          alt="Einrichtung Ansicht"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col clinic-teaser__info">
                          <h4>{location.company}</h4>
                          <address>
                            {location.address}
                            <br />
                            {location.zip} {location.city}
                          </address>

                          <div className="mt-1 d-flex flex-wrap">
                            {location.categories.map((cat, index) => (
                              <span className="mini-cat" key={index}>
                                {cat.title}
                              </span>
                            ))}
                          </div>
                        </div>

                        <div className="col-auto">
                          <div className="clinic-teaser__status">
                            {/* <div>
                              <span
                                className={
                                  location.status === "green"
                                    ? "active bg-success"
                                    : ""
                                }
                              ></span>
                              <span
                                className={
                                  location.status === "yellow"
                                    ? "active bg-warning"
                                    : ""
                                }
                              ></span>
                              <span
                                className={
                                  location.status === "red"
                                    ? "active bg-danger"
                                    : ""
                                }
                              ></span>
                            </div>
                            Freie Plätze */}
                            <p>
                              <small>{location.distanceFormatted}</small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
          </div>
        </Collapse>
      </div>
    </div>
  );
}

export default Locations;
