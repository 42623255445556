import React from "react";
import parse from "html-react-parser";

function TextElement({ item }) {
  const textClass = "text-element d-flex h-100 py-3";

  switch (item.wrap) {
    case "h1":
      return (
        <div className={textClass}>
          <h1>{item.content}</h1>
        </div>
      );
    case "h2":
      return (
        <div className={textClass}>
          <h2>{item.content}</h2>
        </div>
      );
    case "h3":
      return (
        <div className={textClass}>
          <h3>{item.content}</h3>
        </div>
      );
    case "h4":
      return (
        <div className={textClass}>
          <h4>{item.content}</h4>
        </div>
      );
    case "h5":
      return (
        <div className={textClass}>
          <h5>{item.content}</h5>
        </div>
      );
    case "h6":
      return (
        <div className={textClass}>
          <h6>{item.content}</h6>
        </div>
      );
    case "strong":
      return (
        <div className={textClass}>
          <p>
            <strong>{item.content}</strong>
          </p>
        </div>
      );
    case "p":
      return (
        <div className={textClass}>
          <p>{item.content}</p>
        </div>
      );
    default:
      return <div className={textClass}>{parse(item.content)}</div>;
  }
}

export default TextElement;
