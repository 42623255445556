import axios from "axios";
import * as Sentry from "@sentry/react";

export let baseURL = "";
baseURL = process.env.REACT_APP_API_URL;

axios.defaults.withCredentials = true;

// prettier-ignore
const baseHeaders = {
  // "Content-Type": "application/json",
  // "Accept": "application/json",
  // Origin "^http(s)?://(.+)$" REQUEST_ORIGIN=$0
  // Access-Control-Allow-Origin :%{REQUEST_ORIGIN}e env=REQUEST_ORIGIN
  // "Access-Control-Allow-Methods": "GET, OPTIONS",
  // "Access-Control-Allow-Headers" :"Origin,X-Requested-With,Authorization,Content-Type,Accept,Cache-Control,Credentials",
  // "Access-Control-Allow-Credentials" :"true"
};

// #####################################################################
// PATH: SERVICES / LOCATION
// #####################################################################
export function loadResult(path) {
  let url = baseURL + path;
  return axios.get(url);
}

// #####################################################################
// PUBLIC FOLDER
// #####################################################################
const getPublicFolderURL = baseURL + "/advisor/ehs/public";

export let assetURL = "";

export function loadPublicFolderURL() {
  return axios
    .get(getPublicFolderURL)
    .then((response) => (assetURL = response.data.path + "Images/"))
    .catch((err) => Sentry.captureException(err));
}

// #####################################################################
// PDF
// #####################################################################
export const loadPDF = async () => {
  return axios({
    method: "GET",
    url: baseURL + "/advisor/ehs/pdf",
    headers: {
      ...baseHeaders,
    },
  });
};

// #####################################################################
// FORM
// #####################################################################
const formURL = baseURL + "/advisor/v1";

export function loadSteps() {
  return axios({
    method: "GET",
    url: formURL + "/steps",
    headers: {
      ...baseHeaders,
    },
  });
}

export function loadStep(step) {
  return axios({
    method: "GET",
    url: formURL + "/step/" + step,
    headers: {
      ...baseHeaders,
    },
  });
}

export function loadFinish() {
  return axios({
    method: "GET",
    url: formURL + "/finish",
    headers: {
      ...baseHeaders,
    },
  });
}

export function postStep(step, values) {
  return axios({
    method: "POST",
    url: formURL + "/step/" + step,
    headers: {
      ...baseHeaders,
    },
    data: values,
  });
}

export function putStep(step, values) {
  return axios({
    method: "PUT",
    url: formURL + "/step/" + step,
    headers: {
      ...baseHeaders,
    },
    data: values,
  });
}

// #####################################################################
// CLEAR SESSION
// #####################################################################
export function clearSession() {
  return axios({
    method: "POST",
    url: formURL + "/clearsession",
    headers: {
      ...baseHeaders,
    },
  });
}
