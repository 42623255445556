import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import { LoadingContext } from "../contexts/loading";
import { StepsContext } from "../contexts/steps";

const StepsBar = ({
  nextAllowed = true,
  prevAllowed = true,
  nextVisible = true,
  prevVisible = true,
}) => {
  const [, setLoadingCon] = useContext(LoadingContext);
  const [stepsCon] = useContext(StepsContext);

  const navigate = useNavigate();

  const handleNextStep = () => {
    setLoadingCon({ "bool": true, "show": false });
    navigate("/" + stepsCon?.stepData?.next);
  };

  const handlePrevStep = () => {
    setLoadingCon({ "bool": true, "show": false });
    navigate("/" + stepsCon?.stepData?.prev, { replace: true });
  };

  let prevBtn;
  let nextBtn;

  if (stepsCon?.current > 0) {
    prevBtn = (
      <button
        className="step-btn step-btn--prev"
        onClick={() => handlePrevStep()}
        disabled={!prevAllowed}
      >
        <FontAwesomeIcon icon={faAngleLeft} /> zurück
      </button>
    );
  }

  if (stepsCon?.current < stepsCon?.steps?.length) {
    nextBtn = (
      <button
        className="step-btn step-btn--next"
        onClick={() => handleNextStep()}
        disabled={!nextAllowed}
      >
        weiter <FontAwesomeIcon icon={faAngleRight} />
      </button>
    );
  }

  return (
    <div className="steps-navigation">
      <div className="row align-content-center justify-content-between">
        {prevVisible && <div className="col-auto">{prevBtn}</div>}
        {nextVisible && <div className="col-auto">{nextBtn} </div>}
      </div>
    </div>
  );
};

export default StepsBar;
