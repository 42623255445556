import { createContext, useState } from "react";

const ScrollContext = createContext([{}, () => {}]);

const ScrollProvider = ({ children }) => {
  const [state, setState] = useState("");
  return (
    <ScrollContext.Provider value={[state, setState]}>
      {children}
    </ScrollContext.Provider>
  );
};

export { ScrollContext, ScrollProvider };
