import { createContext, useState } from "react";

const StepsContext = createContext([{}, () => {}]);

const StepsProvider = ({ children }) => {
  const [steps, setSteps] = useState({
    "current": 0,
    "steps": [],
    "stepData": {},
  });
  return (
    <StepsContext.Provider value={[steps, setSteps]}>
      {children}
    </StepsContext.Provider>
  );
};

export { StepsContext, StepsProvider };
