import React, { useState, useEffect, useContext } from "react";
import Collapse from "react-bootstrap/Collapse";
import { StepsContext } from "../contexts/steps";

const ProgressBar = () => {
  const [stepsCon] = useContext(StepsContext);

  const [size, setSize] = useState("0%");

  useEffect(() => {
    let allSteps = stepsCon?.steps?.length - 1;
    let res = "0%";
    if (stepsCon?.current === allSteps) {
      res = "100%";
    } else {
      let chunk = 100 / allSteps;
      res = chunk * stepsCon?.current + chunk / 2 + "%";
    }
    setSize(res);
    // eslint-disable-next-line
  }, [stepsCon?.steps]);

  return (
    <Collapse in={stepsCon?.steps?.length > 0}>
      <div className="steps-progress">
        <div className="steps-progress__progress-bar" v-if="showProgress">
          <span style={{ width: size }}></span>
        </div>
        <div className="container-fluid steps-progress__text">
          <div className="row">
            <div className="col">
              <ul>
                {stepsCon?.steps?.map((step, index) => {
                  if (index < stepsCon?.steps.length - 1) {
                    return (
                      <li key={index}>
                        <span>{step.label}</span>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Collapse>
  );
};

export default ProgressBar;
