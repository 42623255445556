import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./sentry";

import "bootstrap/dist/css/bootstrap.css";

import App from "./App";

import StartView from "./components/views/StartView";
import FormView from "./components/views/FormView";
import ResultView from "./components/views/ResultView";
import PdfView from "./components/views/PdfView";
import BaseView from "./components/views/BaseView";

import { StateProvider } from "./contexts/state";
import { LoadingProvider } from "./contexts/loading";
import { StepsProvider } from "./contexts/steps";
import { FormProvider } from "./contexts/form";
import { LocationsProvider } from "./contexts/locations";
import { ServicesProvider } from "./contexts/services";
import { ScrollProvider } from "./contexts/scroll";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <LoadingProvider>
      <StateProvider>
        <StepsProvider>
          <FormProvider>
            <LocationsProvider>
              <ServicesProvider>
                <ScrollProvider>
                  <BrowserRouter>
                    <Routes>
                      <Route path="/" element={<App />}>
                        <Route index element={<BaseView />} />
                        <Route path="start" element={<StartView />} />
                        <Route path="result" element={<ResultView />} />
                        <Route path="pdf" element={<PdfView />} />
                        <Route path="admin" />
                        <Route path="*" element={<FormView />} />
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </ScrollProvider>
              </ServicesProvider>
            </LocationsProvider>
          </FormProvider>
        </StepsProvider>
      </StateProvider>
    </LoadingProvider>
  </React.StrictMode>
);
