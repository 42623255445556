// import { useEffect } from "react";
import * as Sentry from "@sentry/react";
// import {
//   createRoutesFromChildren,
//   matchRoutes,
//   useLocation,
//   useNavigationType,
// } from "react-router-dom";

Sentry.init({
  dsn: "https://f2f650108da748f8a189060d293e93b8@o990541.ingest.sentry.io/6345894",
  // integrations: [
  //   See docs for support of different versions of variation of react router
  //   https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
  //   Sentry.reactRouterV6BrowserTracingIntegration({
  //     useEffect,
  //     useLocation,
  //     useNavigationType,
  //     createRoutesFromChildren,
  //     matchRoutes,
  //   }),
  //   Sentry.replayIntegration(),
  // ],
  // tracesSampleRate: 0.1,
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
});
