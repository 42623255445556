import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { getColClass } from "../../utilities";
import { baseURL } from "../../api/client";

function InputElement({ item, index }) {
  const [classValue, setClassValue] = useState("col-12");

  useEffect(() => {
    if (index.length < 2) {
      let newClassValue = getColClass(classValue, item.viewPorts);
      setClassValue(newClassValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row justify-content-center">
      <div className={classValue}>
        <div
          className="link-element"
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          <a
            className="link-btn"
            href={baseURL + item.href}
            target="_blank"
            rel="noopener noreferrer"
            download="Evangelische Heimstiftung - Beratungsbogen"
          >
            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 10 }} /> PDF
            herunterladen
          </a>
        </div>
      </div>
    </div>
  );
}

export default InputElement;
