import { useState, useEffect } from "react";
import _ from "lodash";

export function getColClass(value, viewPorts) {
  let classValue = value;
  if (viewPorts) {
    classValue = viewPorts.sd
      ? (classValue = classValue + " col-sm-" + viewPorts.sd)
      : classValue;
    classValue = viewPorts.md
      ? (classValue = classValue + " col-md-" + viewPorts.md)
      : classValue;
    classValue = viewPorts.lg
      ? (classValue = classValue + " col-lg-" + viewPorts.lg)
      : classValue;
  }
  return classValue;
}

export function cleanList(origin) {
  let list = origin;
  list = _.flatMap(list, (item) => {
    if (item.renderables) {
      return _.map(item.renderables, (rend) => rend);
    }
    return item;
  });
  list = _.flatMap(list, (item) => {
    if (item.renderables) {
      return _.map(item.renderables, (rend) => rend);
    }
    return item;
  });

  // filter elements for show
  list = _.filter(list, (item) => item.type !== "text");
  list = _.filter(list, (item) => item.type !== "image");

  return list;
}

// hook for window size
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
