import { useEffect, useContext } from "react";
import { ServicesContext } from "../../contexts/services";

function ServicesObj({ item, form, stepID, values, setFieldValue }) {
  const [servicesCon] = useContext(ServicesContext);

  useEffect(() => {
    setFieldValue(item.identifier, servicesCon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, stepID, values, servicesCon]);

  return null;
}

export default ServicesObj;
