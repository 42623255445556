import React, { useState, useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faEllipsisH,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import Collapse from "react-bootstrap/Collapse";

import { LoadingContext } from "../../contexts/loading";
import { StepsContext } from "../../contexts/steps";
import { StateContext } from "../../contexts/state";
import { clearSession } from "../../api/client";

import StepsBar from "../StepsBar";

const image = require("../../assets/images/banner.jpg");

function StartView() {
  const [, setLoadingCon] = useContext(LoadingContext);
  const [stepsCon, setStepsCon] = useContext(StepsContext);
  const [, setStateCon] = useContext(StateContext);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  const clrSession = () => {
    window.sessionStorage.removeItem("sidebarData");
    setStateCon({ "status": false, "notifications": [] });
    setStepsCon({ "current": 0, "steps": [], "stepData": {} });
    clearSession()
      .then((response) => {})
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  useEffect(() => {
    clrSession();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let newState = { ...stepsCon };
    newState["stepData"] = stepsCon?.steps[0];
    newState["current"] = 0;
    setStepsCon(newState);
    setTimeout(() => {
      setLoadingCon({ "bool": false, "show": false });
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsCon?.steps]);

  return (
    <div className="my-5 content">
      <img src={image} alt="icon" className="img-fluid" />
      <h2 className="mt-5 mb-3">
        Herzlich Willkommen in Ihrem Gute-Pflege-Center
      </h2>
      <p>
        Im Gute-Pflege-Center finden Sie mit wenigen Klicks die richtigen
        Angebote und Ansprechpersonen für Ihr Anliegen, ob zu Hause oder in
        einer unserer Einrichtungen, ob vorübergehend oder dauerhaft.
      </p>
      {/* <div className="row">
        {teasers.map((teaser, index) => (
          <div className="col-12 col-sm-6" key={index}>
            <Teaser
              url={teaser.url}
              alt={teaser.alt}
              link={teaser.link}
              title={teaser.title}
            />
          </div>
        ))}
      </div> */}
      <Collapse in={open}>
        <div id="step-1-additional-info">
          <div className="additional-info">
            <div className="additional-info__inner">
              <p>
                Wir wissen: Die Lebenssituationen im Alter sind heute sehr
                vielfältig. Deshalb finden wir, dass auch die Angebote
                vielfältig und individuell sein müssen.
              </p>
              <p>
                Mit unserem Gute-Pflege-Center möchten wir Ihnen die geeigneten
                Lösungen für Ihren Bedarf bieten – so dass Sie weiterhin
                selbstständig sein und am gemeinschaftlichen Leben teilhaben
                können. Lebensqualität ist auch im Alter und bei Pflegebedarf
                möglich – und wir möchten Sie gerne dabei unterstützen.
              </p>
            </div>
          </div>
        </div>
      </Collapse>
      <div className="text-center position-relative mt-5">
        <div
          aria-expanded={open}
          aria-controls="step-1-additional-info"
          className="show-toggle-btn"
          onClick={() => setOpen(!open)}
        >
          <span className="when-open" style={!open ? { display: "none" } : {}}>
            Details schliessen
            <i className="icon">
              <FontAwesomeIcon icon={faAngleUp} />
            </i>
          </span>
          <span className="when-closed" style={open ? { display: "none" } : {}}>
            Mehr erfahren
            <i className="icon">
              <FontAwesomeIcon icon={faEllipsisH} />
            </i>
          </span>
        </div>
      </div>
      <Collapse in={stepsCon?.steps?.length > 0}>
        <div className="checkbox-container">
          <h4 className="mb-2">Datenschutz und Nutzungsbedingungen</h4>
          <p>
            Ihre Daten sind sicher. Informationen, die Sie im Gute-Pflege-Center
            eintragen, dienen ausschließlich dazu, ein für Sie passendes
            Leistungsangebot der Evangelischen Heimstiftung zu finden. Die Daten
            werden nicht weitergegeben.
          </p>
          <label>
            <input
              type="checkbox"
              checked={checked}
              onChange={() => {
                setChecked(!checked);
              }}
            />
            Ich habe die{" "}
            <a
              href="https://www.ev-heimstiftung.de/datenschutz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nutzungsbedingungen
            </a>{" "}
            gelesen und akzeptiere sie.
            <FontAwesomeIcon
              icon={faCheck}
              style={!checked ? { display: "none" } : {}}
            />
          </label>
        </div>
      </Collapse>
      <StepsBar nextAllowed={checked} />
    </div>
  );
}

export default StartView;
