import React, { useState, useEffect, useRef, useContext } from "react";
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

import { cleanList, getColClass } from "../../utilities";
import { putStep } from "../../api/client";
import { StepsContext } from "../../contexts/steps";
import { FormContext } from "../../contexts/form";

function SelectElement({
  item,
  form,
  errors,
  index,
  field,
  meta,
  stepID,
  values,
  setFieldValue,
}) {
  const [stepsCon, setStepsCon] = useContext(StepsContext);
  const [formCon, setFormCon] = useContext(FormContext);

  const node = useRef();

  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState([]);

  const setFormUpdate = (bool) => {
    let newForm = { ...formCon };
    newForm["update"] = bool;
    setFormCon(newForm);
  };

  const updateStep = async (id, valObject) => {
    let result = await putStep(id, valObject)
      .then((response) => {
        if (response.data.steps !== undefined) {
          let index = _.findIndex(stepsCon?.steps, function (o) {
            return o.identifier === id;
          });

          let newState = { ...stepsCon };
          let newSteps = [...stepsCon?.steps];
          newSteps[index].renderables = response.data.steps.renderables;
          newState["steps"] = newSteps;
          newState["stepData"] = newSteps[index];
          setStepsCon(newState);
        }

        return response;
      })
      .catch((err) => {
        Sentry.captureException(err);
        return err;
      });
    return result.data;
  };

  const handleClickOutside = (e) => {
    if (!node.current.contains(e.target)) setOpen(false);
  };

  let classValue = "col-12";
  if (index.length < 2) {
    classValue = getColClass(classValue, item.viewPorts);
  }

  useEffect(() => {
    if (item?.config?.type === "time") {
      if (item?.config?.condition) {
        if (form.values[item?.config?.condition]) {
          let d = new Date();
          let compareDate = new Date(values[item?.config?.condition]);
          if (d.getDate() === compareDate.getDate()) {
            let hour = d.getHours();
            let disArray = [];
            item?.config?.values?.forEach((option, index) => {
              if (hour < option.from || hour > option.to) {
                disArray.push(index);
              }
            });
            setDisabled(disArray);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [open]);

  return (
    <div className="row" ref={node}>
      <div className={classValue}>
        <div className="select-element">
          {!item.hideLabel && <div className="input-title">{item.label}</div>}

          {/* hide real select */}
          <div className="d-none">
            <select
              name={item.identifier}
              id={item.identifier}
              value={meta.value}
              {...field}
            >
              <option value={item.prependOptionValue}>
                {item.prependOptionLabel}
              </option>

              {item.options &&
                item.options.map((option, i) => (
                  <option key={i} value={option[item.optionValueField]}>
                    {option[item.optionLabelField]}
                  </option>
                ))}
            </select>
          </div>
          <div className={open ? "select-area open" : "select-area"}>
            <div
              className={
                field.value !== "" && field.value !== undefined
                  ? "select-item checked"
                  : "select-item"
              }
              onClick={() => setOpen(!open)}
            >
              <span className="icon-checked">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              {field.value && <span className="label">{field.value}</span>}
              {!field.value && (
                <span className="label">{item.prependOptionLabel}</span>
              )}
              {!open && <FontAwesomeIcon icon={faAngleDown} />}
              {open && <FontAwesomeIcon icon={faAngleUp} />}
            </div>

            <div className="options">
              {item.options &&
                item.options.map((option, i) => {
                  let disIndex = disabled.indexOf(i);
                  if (disIndex > -1) {
                    return (
                      <div
                        key={i}
                        className={
                          field.value === option[item.optionValueField]
                            ? "option disabled checked"
                            : "option disabled"
                        }
                      >
                        <span className="icon-checked">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                        <span className="label">
                          {option[item.optionLabelField]}
                        </span>
                      </div>
                    );
                  }

                  return (
                    <div
                      key={i}
                      className={
                        field.value === option[item.optionValueField]
                          ? "option checked"
                          : "option"
                      }
                      onClick={async () => {
                        form.setFieldValue(
                          item.identifier,
                          option[item.optionValueField]
                        );
                        let valObject = {};
                        valObject[item.identifier] =
                          option[item.optionValueField];
                        let result = await updateStep(stepID, valObject);

                        // signal start of update
                        setFormUpdate(true);

                        // check if values got deleted
                        if (result.values) {
                          if (!_.isEqual(result.values, values)) {
                            let list = cleanList(
                              stepsCon?.stepData?.renderables
                            );
                            list.forEach((item) => {
                              if (
                                !result.values.hasOwnProperty(item.identifier)
                              ) {
                                result.values[item.identifier] = "";
                                if (_.isArray(values[item.identifier])) {
                                  setFieldValue(item.identifier, []);
                                }
                                setFieldValue(item.identifier, "");
                              }
                            });
                          }

                          let newForm = { ...formCon };
                          newForm["values"] = result.values;
                          setFormCon(newForm);
                        }

                        // add possible errors
                        if (result.errors && result.errors.length !== 0) {
                          form.setFieldError(
                            item.identifier,
                            result.errors[item.identifier]
                          );
                        } else {
                          let newErrors = _.omit(errors, [item.identifier]);
                          form.setErrors(newErrors);
                        }

                        // signal end of update
                        setFormUpdate(false);

                        setOpen(false);
                      }}
                    >
                      <span className="icon-checked">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                      <span className="label">
                        {option[item.optionLabelField]}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {meta.error && <div className="alert alert-danger">{meta.error}</div>}
      </div>
    </div>
  );
}

export default SelectElement;
