import React from "react";

import { getColClass } from "../../utilities";

function TextareaElement({ item, field, meta, index }) {
  let classValue = "col-12";
  if (index.length < 2) {
    classValue = getColClass(classValue, item.viewPorts);
  }
  return (
    <div className="row justify-content-center">
      <div className={classValue}>
        <div className="textarea-element">
          {!item.hideLabel && <h3 className="title">{item.label}</h3>}
          <textarea
            id={item.identifier}
            required={item.required}
            readOnly={item.readonly}
            placeholder={item.placeholder}
            {...field}
          ></textarea>
          {meta.error && <div className="alert alert-danger">{meta.error}</div>}
        </div>
      </div>
    </div>
  );
}

export default TextareaElement;
