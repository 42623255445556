import React, { useEffect, useState, useRef, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import FormElement from "../form/FormElement";
import { loadStep } from "../../api/client";
import { LoadingContext } from "../../contexts/loading";
import { StepsContext } from "../../contexts/steps";
import { StateContext } from "../../contexts/state";
import { FormContext } from "../../contexts/form";
import { ScrollContext } from "../../contexts/scroll";

function FormView() {
    const [loadingCon, setLoadingCon] = useContext(LoadingContext);
    const [stepsCon, setStepsCon] = useContext(StepsContext);
    const [stateCon, setStateCon] = useContext(StateContext);
    const [formCon, setFormCon] = useContext(FormContext);
    const [scrollCon, setScrollCon] = useContext(ScrollContext);

    const [ready, setReady] = useState(false);
    const formRef = useRef(null);

    // enable nav links
    const location = useLocation();
    const navigate = useNavigate();

    const handlePrevStep = () => {
        setLoadingCon({ "bool": true, "show": false });
        navigate("/" + stepsCon?.stepData?.prev, { replace: true });
    };

    useEffect(() => {
        let newState = { ...stateCon };
        newState["notification"] = "";
        setStateCon(newState);

        let id = location.pathname.replace("/", "");

        let index = _.findIndex(stepsCon?.steps, function (o) {
            return o.identifier === id;
        });

        if (index > -1) {
            if (scrollCon === "") setScrollCon("top");

            loadStep(id)
                .then((response) => {
                    if ("timestamp" in response.data.values) {
                        navigate("/", { replace: true });
                    }

                    if (response.data.steps !== undefined) {
                        let newState = { ...stepsCon };

                        newState["current"] = index;

                        let newSteps = [...stepsCon?.steps];
                        newSteps[index].renderables =
                            response.data.steps.renderables;
                        newState["steps"] = newSteps;
                        newState["stepData"] = newSteps[index];
                        if (response.data.steps.validation) {
                            newSteps[index].validation =
                                response.data.steps.validation;
                        }
                        setStepsCon(newState);

                        let newForm = { ...formCon };
                        if (response.data.values.length === 0) {
                            newForm["values"] = {};
                        } else {
                            newForm["values"] = response.data.values;
                        }
                        newForm["update"] = false;
                        setFormCon(newForm);

                        setReady(true);
                    }
                    if (scrollCon === "") setScrollCon("top");
                    setLoadingCon({ "bool": false, "show": false });
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    navigate("/", { replace: true });
                });
        } else {
            navigate("/", { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepsCon?.current, loadingCon?.bool, loadingCon?.show, location]);

    if (!ready) return null;

    return (
        <div className="my-5">
            <FormElement formRef={formRef}>
                <div className="steps-navigation">
                    <div className="row align-content-center justify-content-between">
                        <div className="col-auto">
                            {stepsCon?.current > 1 && (
                                <div
                                    className="step-btn step-btn--prev"
                                    onClick={handlePrevStep}
                                >
                                    <FontAwesomeIcon icon={faAngleLeft} />{" "}
                                    zurück
                                </div>
                            )}
                        </div>
                        <div className="col-auto">
                            {stateCon.disabled && (
                                <button
                                    type="submit"
                                    className="step-btn step-btn--next"
                                    disabled
                                >
                                    weiter{" "}
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </button>
                            )}
                            {!stateCon.disabled && (
                                <button
                                    type="submit"
                                    className="step-btn step-btn--next active"
                                    disabled={formCon?.update}
                                >
                                    weiter{" "}
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </FormElement>
        </div>
    );
}

export default FormView;
